<template>
    <v-card class="py-10" outlined elevation="0"  :style="{borderColor: parameters.primaryColor}">
        <h2 class="font-weight-light">
            Seu pedido foi realizado!
        </h2>
        <v-subheader class="justify-center subtitle-2">Muito obrigado por comprar com a gente 😊</v-subheader>
        <v-card-actions class="mt-4 justify-center">
            <v-btn outlined :color="parameters.secondaryColor" @click="close">Continuar comprando</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        cart: {
            get() {
                return this.$store.state.cart;
            },
        },
    },
    methods:{
        close(){
            this.$router.push('/home')
            this.cart.showOkOrder = false
        }
    }
};
</script>

<style>
</style>