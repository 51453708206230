<template>
    <v-app-bar
        app
        height="81px"
        color="#FDFDFD"
        elevate-on-scroll
        ref="appBar"
        :class="$mq == 'sm' ? '' : 'd-flex justify-center'"
        class="pt-2"
    >
        <a @click="$router.push('/')">
            <img
                :src="logo"
                alt="logo"
                height="60px"
            />
        </a>
        <v-toolbar-title class="font-weight-bold primary--text ml-4">{{ parameters.store.cf1fant }}</v-toolbar-title>
    </v-app-bar>
</template>

<script>
export default {
    computed:{
        logo(){
            return `../../../../img/${process.env.VUE_APP_ENV_NAME}/logo.png`
        },
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
    }
};
</script>

<style>
</style>