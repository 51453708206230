<template>
    <v-list tile>
        <v-list-item-group v-model="cart.selectedAddress" color="secondary" mandatory>
            <v-list-item three-line>
                <v-list-item-avatar>
                    <v-icon color="primary" large>mdi-home</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="text-left body-2"><span>{{ `${customerAddress.cf1ender1}, ${customerAddress.cf1numero}`}}</span></v-list-item-title>
                    <v-list-item-title class="text-left body-2"><span>{{ customerAddress.cf1bairro }}</span></v-list-item-title>
                    <v-list-item-subtitle class="text-left overline"><span>{{ customerAddress.cf1cep | cep}}</span></v-list-item-subtitle>
                    <v-list-item-subtitle class="text-left overline"><span>{{ `${customerAddress.cf3nome}/${customerAddress.cf3estado}` }}</span></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn icon color="primary" small @click="goToCustomer">
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
            <v-card-actions v-if="!user.shippingAddress || (Object.keys(user.shippingAddress).length === 0 && user.shippingAddress.constructor === Object)">
                <v-btn
                    class="my-1 caption text-none"
                    :color="parameters.secondaryColor"
                    plain
                    @click="goToCustomer"
                    left
                >
                    <v-icon class="pr-2">mdi-truck-fast</v-icon>
                    Cadastrar um Endereço de entrega
                </v-btn>
            </v-card-actions>
            <v-list-item v-else three-line>
                <v-list-item-avatar>
                    <v-icon color="primary" large>mdi-map-marker-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="text-left body-2">{{ user.shippingAddress.cfbendent }}</v-list-item-title>
                    <v-list-item-subtitle class="text-left overline">{{ user.shippingAddress.cfbcepent | cep}}</v-list-item-subtitle>
                    <v-list-item-subtitle class="text-left overline">{{ `${user.shippingAddress.cf3nome}/${user.shippingAddress.cf3estado}` }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn icon color="primary" small @click="goToCustomer">
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data: () => ({
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        user: {
            get() {
                return this.$store.state.login.user;
            },
        },
        cart: {
            get() {
                return this.$store.state.cart;
            },
        },
        ...mapGetters({
            customerAddress: 'login/customerAddress',
        })
    },
    methods:{
        goToCustomer(){
            this.$router.push("/minhaconta")
        },
    }
}
</script>

<style>

</style>